.propertyContainer {
  margin-top: 120px !important;
  border-top: 2px solid #c0cdf2;
  border-bottom: 2px solid #c0cdf2;
}
.propertyServices {
  font-family: "Roboto-400" !important;
  font-size: 14px !important;
  color: #000 !important;
  line-height: 1.8 !important;
}
.propertyServicesText{
  font-family: "Roboto-400" !important;
  font-size: 14px !important;
  color: #000 !important;
  display: flex !important;
  align-content: center !important;
  line-height: 1.79;
}