.property_heading {
  position: absolute;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 69px;
  display: flex;
  align-items: center;
  color: #091638;
}
.property_sub_heading {
  position: absolute;
  width: 611px;
  height: 69px;
  /*font-family: Circular Std;*/
  font-style: normal;
  font-weight: normal;
  font-size: 21.25px;
  line-height: 28px;
  display: flex;
  align-items: center;
  font-feature-settings: "liga" off;
  color: #091638;
}
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

/** New Property **/

.personalDetailsContainer {
  padding: 30px !important;
  background-color: #f6f6f6 !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}
.formHeading {
  font-family: "Roboto-400" !important;
  font-size: 14px !important;
  color: #000 !important;
  text-align: left !important;
}
.formInputField .ant-input {
  border: 1.5px solid #c0cdf2 !important;
  background-color: #f6f6f6 !important;
  padding: 8px !important;
}
.formInputField .ant-input::placeholder {
  color: #7b7b7b !important;
  font-family: "Roboto-400" !important;
  font-size: 12px !important;
}

.formSelectField .ant-select-selector {
  font-family: "Roboto-400" !important;
  border: 1.5px solid #c0cdf2 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  background-color: #f6f6f6 !important;
  height: 40px !important;
}
.formMultiSelectField .ant-select-selector {
  border: 1.5px solid #c0cdf2 !important;
  border-radius: 8px !important;
  background-color: #f6f6f6 !important;
}
.formMultiSelectField .ant-select-selector .ant-select-selection-placeholder {
  color: #7b7b7b !important;
  font-size: 12px !important;
}
.formMultiSelectField
  .ant-select-selection-overflow
  .ant-select-selection-item {
  font-family: "Roboto-400" !important;
  font-size: 14px !important;
  background: none !important;
  color: #000 !important;
  box-shadow: none !important;
}
.formSelectField .ant-select-selector .ant-select-selection-placeholder {
  color: #7b7b7b !important;
  font-size: 12px !important;
}
.formSelectField .ant-select-selection-item {
  font-size: 14px !important;
}
.formSelectField .ant-select-arrow {
  top: 50% !important;
  transform: translateY(45%);
}
.formInputNumberField {
  font-family: "Roboto-400" !important;
  font-size: 14px !important;
  color: #000 !important;
  text-align: left !important;
}
.formInputNumberField .ant-input-number {
  border: 1.5px solid #c0cdf2 !important;
  background-color: #f6f6f6 !important;
}
.formInputNumberField .ant-input-number-input::placeholder {
  display: flex !important;
  align-items: center !important;
  color: #7b7b7b !important;
  font-family: "Roboto-400" !important;
  font-size: 12px !important;
}
.formTextAreaField .ant-input-outlined {
  border: 1.5px solid #c0cdf2 !important;
  background-color: #f6f6f6 !important;
  padding-top: 5px !important;
}
.formTextAreaField .ant-input::placeholder {
  color: #7b7b7b !important;
  font-family: "Roboto-400" !important;
  font-size: 12px !important;
}
.clearFormButton {
  font-family: "Roboto-500" !important;
  font-size: 14px !important;
  color: #000 !important;
  padding: 20px !important;
  margin-right: 10px !important;
  border: none !important;
  background-color: #e0e0e0 !important;
}
.submitFormButton {
  font-family: "Roboto-500" !important;
  font-size: 14px !important;
  color: #fff !important;
  padding: 20px !important;
  margin-right: 10px !important;
  border: none !important;
  background-color: #1c3988 !important;
}
.segmented {
  font-family: "Roboto-400" !important;
  border: 1.5px solid #c0cdf2 !important;
  padding: 5px !important;
  border-radius: 8px !important;
}
.segmentedForm {
  border: 1px solid #ccc !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  font-size: 13px !important;
  height: 32px !important;
  padding: 0px !important;
}
