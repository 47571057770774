.location {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #141b2d;
}
.price {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #1c3988;
}
.button {
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.viewimages {
  position: absolute;
  left: 5.68%;
  right: 64.49%;
  top: 40.49%;
  bottom: 56.16%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.typography1 {
  font-family: "Roboto-700";
  font-size: 18px;
  color: #1c3988;
  line-height: 1.5 !important;
}
.typography2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  color: #141b2d;
  padding-left: 5px;
}
.semi_furnished {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding-left: 5px;
  /* identical to box height */

  /* text color */
  color: #141b2d;
}
.end {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  margin-top: 30px;
  /* Gray 3 */
  color: #828282;
}
.pCard {
  max-width: 320px;
  width: 100%;
  /* border: 1px solid #f3f3fa; */
  border-radius: 10px !important;
  position: relative;
  z-index: 10 !important;
  pointer-events: auto;
  /* position: relative; */
}
/* .pCard:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  cursor: pointer !important;
} */
.pcCard {
  max-width: 320px;
  width: 100%;
  min-height: 530;
  border: 1px solid #f3f3fa;
  border-radius: 5;
  /* position: relative; */
}
.pCardImage {
  max-width: 320px;
  width: 100%;
  /* height:250px; */
}
.projectImage {
  object-fit: cover !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.viewProjectImage {
  object-fit: cover !important;
  border-radius: 10px !important;
}
.projectLocation {
  display: inline-flex !important;
  align-items: center !important;
  gap: 8px !important;
}
.custom-scrollbar {
  max-height: 150px !important;
  overflow-y: auto !important;
  width: 100% !important;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 10px;
}
