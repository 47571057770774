.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.dashboard_reminders{
  min-width: 300px;
}
body{
  overflow-x: hidden !important;
  margin: 0 !important;
  height: 100% !important; 
}
/* .ant-image{
  margin-right:5px;
} */

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cWidget{
  padding:100px !important;
}
/* .ant-typography{
  font-family: 'IBM Plex Sans', sans-serif !important;
} */
a.ant-typography{
  color:#fff !important;
}
@font-face {
  font-family: 'Roboto-700';
  src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-600';
  src: url('../public/fonts/Roboto-SemiBold.ttf');
}
@font-face {
  font-family: 'Roboto-500';
  src: url('../public/fonts/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-400';
  src: url('../public/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-300';
  src: url('../public/fonts/Roboto-Light.ttf');
}

.ant-layout{
  background-color: #fff !important;
}

.ant-select-selector {
  border-radius: 8px !important;
}
.ant-picker-cell-inner{
  width: 40px !important; 
}
.calenderClass .ant-picker-panel {
  padding-top: 15px !important;
}



