.error_style {
    color: rgb(248, 77, 77);
    display: flex;
    font-size: 12px;
    font-family: 'Roboto-400' !important;
    margin-top: 3px !important;
}

.create_client_field {
    margin: 15px;
    /* min-width: 326px; */
}
.create_checkbox {
    margin: 15px;
}
.notes_box {
    padding: 15px;
}

/* .ant-modal-content {
    width: 760px;
} */

.rowWithPadding {
    padding: 15px 15px 0 15px;
    /* margin-bottom: 0; */
}
.ant-table-thead > tr > th {
 font-size: 10px;
}
.ant-table-tbody > tr > td {
    font-size: 10px;
}
.clientName_button{
    font-size: 10px !important;
}
.onHoldRow td{
    background-color: #fee6e6 !important;
}
.onHoldRow:hover td{
    background-color: #fdcece !important;
}