.group1 {
  position: absolute;
  left: 0px;
  top: 0px;
}

.header{
  padding: 0;
  background: #ffffff;
  height: auto;
}

.banner {
background: linear-gradient(198.73deg, rgba(0, 0, 0, 0.0001) 15.31%, rgba(0, 0, 0, 0.837986) 67.37%);
}
.bannerText {
/* position: absolute; */
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 900;
font-size: 75px;
line-height: 86px;
color: #FFFFFF !important;
}
.bannerDescription {
/* position: absolute; */
font-style: normal;
font-weight: normal;
font-size: 23px;
line-height: 34px;
font-feature-settings: 'liga' off;
color: #FFFFFF;
}
.property_type_box {
  position: absolute;
left: 20px;
top: 20px;
background: #FFFFFF;
border: 1px solid #BDBDBD;
box-sizing: border-box;
box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
border-radius: 4px
}
.search_location {
  position: absolute;
left: -70px;
top: 20px;
background: #FFFFFF;
border: 1px solid #BDBDBD;
box-sizing: border-box;
box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
border-radius: 4px;
}
.search_button {
  position: absolute;
left: -50px;
top: 20px;
color: #FFFFFF;
background: #1C3988;
border: 1px solid #BDBDBD;
box-sizing: border-box;
border-radius: 4px;
}
.advance_search {
position: absolute;
width: 136px;
height: 29px;
left: 160px;
top: 700px;
 /*font-family: Circular Std;*/
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 28px;
display: flex;
align-items: center;
color: #FFFFFF;
}


.card_heading {
font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 21.248px;
line-height: 28px;
color: #141B2D;
}
.location {
  position: absolute;
 /*font-family: Circular Std;*/
font-style: normal;
font-weight: normal;
font-size: 17px;
line-height: 22px;
color: #141B2D;
}
.price {
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 27px;
color: #1C3988;
}
.button{
  position: absolute;
  /* left: 67.61%; */
  /* right: 4.26%; */
  /* top: 36.55%; */
  /* bottom: 57.09%; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  
}
.viewimages{
  position: absolute;
  left: 5.68%;
  right: 64.49%;
  top: 40.49%;
  bottom: 56.16%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.typography{
   /*font-family: Circular Std;*/
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  color: #141B2D;
}
