/* .text {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 21.25px;
  line-height: 28px;
  
  align-items: center;
  font-feature-settings: "liga" off;

  color: #091638;
}

.aboutimg {
  height: auto;
  width: 100%;
  margin: 20px auto;
  padding: 25px 0px;
}
.aboutContent {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .aboutimg {
    width: 100%;
    margin: 20px auto !important;
    padding: 10px 0px;
  }
  .aboutContent {
    padding-top: 0px;
  }
} */

/** New **/
.aboutusTextContainer {
  background-color: #eaeefb !important;
  border-radius: 10px !important;
  padding: 30px 20px !important;
}
.aboutusText {
  font-family: "Roboto-700" !important;
  font-size: 70px !important;
  color: #1c3988 !important;
  line-height: normal !important;
}
.aboutusDataContainer {
  border: 2px solid #c0cdf2 !important;
  border-radius: 10px !important;
  padding: 20px 10px !important;
}
.aboutusImage {
  height: 320px !important;
  border-radius: 10px !important;
  object-fit: cover !important;
}
.aboutusNumber {
  font-family: "Roboto-700" !important;
  font-size: 22px !important;
  color: #1c3988 !important;
  line-height: normal !important;
}
.customScrollbar::-webkit-scrollbar {
  width: 8px;
}

.customScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  background: #e6e4e4; 
  border-radius: 10px;
}

.empText{
  color:#1677ff !important;
}
.propertyText{
  color:#1677ff !important;
  font-size: 11px !important;
}

