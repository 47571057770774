.logo {
  background: url('../../assets/images/svmp.png');
  background-size: cover;
  height: 170px;
  width: 122px;
  margin: 8px 38px;
}
.sider{
  height: 1000px;
}
.site-layout-sub-header-background {
  background: #ffffff;
}

.site-layout-background {
  background: #ffffff;
  padding: 15px;
  margin: 15px;
  height: 91.9vh;
  /* overflow-y: scroll; */
}

#container {
  padding: 0 !important;
}

#container>.app-view {
  height: 100%;
}

/* .content_view{
  background: rgb(230, 230, 230);
} */

.dashboard_table {
  max-width: 82vw;
  padding: 15px;
}

.app-view {
  width: 100vw;
}

.ant-layout-sider, .ant-layout-sider-dark {
  background: #ffffff;
}
