.heroContainer{
  position: relative;
  width: 100%;
  height: 80vh;
  background: url("../assets/images/banner.webp") no-repeat center center/cover;
  display: flex;
  align-items: end;
  justify-content: center;
}

.heroContainer::before, .newPropertyContainer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(
    to top,
    rgba(28, 57, 136, 1),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}

.heroContent {
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1;
}

.textContainer {
  text-align: center;
  margin-bottom: 40px !important;
}

.heroTitle {
  font-family: "Roboto-700" !important;
  font-size: clamp(30px, 7vw + 10px, 70px);
  color: #fff;
  line-height: normal !important;
}

.heroSubtitle {
  font-family: "Roboto-400" !important;
  color: #fff;
  font-size: 18px !important;
}

@media (max-width: 768px) {
  .heroContainer, .newPropertyContainer {
    height: 60vh !important;
  }
  .textContainer {
    margin-bottom: 20px !important;
  }
  .heroSubtitle {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .heroSubtitle {
    font-size: 14px !important;
  }
}

.text-container {
  text-align: center;
  position: relative;
}
.propertySearch {
  font-family: "Roboto-700" !important;
  font-size: 30px !important;
  color: #1c3988 !important;
  line-height: 1 !important;
}
.searchContainer .ant-select-selector{
  font-family: "Roboto-400" !important;
  border: 1.5px solid #c0cdf2 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}
.searchContainer .ant-select-selector .ant-select-selection-placeholder {
  color: #7b7b7b !important;
  font-size: 14px !important;
}
.searchContainer .ant-select-selection-item {
  font-size: 14px !important;
}
.searchButton {
  font-family: "Roboto-400" !important;
  font-size: 14px !important;
  background-color: #1c3988 !important;
  color: #fff !important;
  border-radius: 8px !important;
  width: 100% !important;
}
.spinnerButton .ant-spin-dot i{
  background-color: #fff !important;
}
.newPropertyContainer{
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: end;
  justify-content: center;
  background: url("../assets/images/propertyBanner.webp") no-repeat center center/cover;
}
