.postProperty {
  font-family: "Roboto-500" !important;
  font-size: 14px;
  background-color: #e0e0e0 !important;
  border-radius: 4px;
  border: none !important;
  cursor: pointer !important;

  &:hover {
    color: #000000 !important;
  }
}
.signIn {
  background: #1c3988;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  font-family: "Roboto-500" !important;
  font-size: 14px;
  border-radius: 4px;

  &:hover {
    background: #1c3988 !important;
    color: #ffffff !important;
  }
}
.navbarText {
  cursor: pointer !important;
  font-family: "Roboto-500" !important;
  font-size: 14px;

  &:hover{
    color: #1c3988 !important ;
  }
}
.menuBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.menuBar .ant-menu-item:hover {
  background: none !important; 
  color: inherit !important;  
  cursor: default !important; 
}
.sideBySideButtons {
  display: flex;
  gap: 15px; 
  width: 100%;
  justify-content: space-between;
}
.logoHeading{
  font-family: "Roboto-700" !important;
  font-size: 16px;
  color: #1c3988;
}
.logoText{
  font-family: "Roboto-400" !important;
  font-size: 8px;
}

